import type { DynamicHelpConfig } from '../../default/cms/dynamicHelp.types';

export const dynamicHelp: DynamicHelpConfig = {
  labelMessageKey: 'forms.contact.dynamicSubject.howCanWeHelp',
  items: [
    { messageKeySelectOption: 'forms.contact.dynamicSubject.changeDataEmail' },
    { messageKeySelectOption: 'forms.contact.dynamicSubject.changeData' },
    { messageKeySelectOption: 'forms.contact.dynamicSubject.customerAccount' },
    { messageKeySelectOption: 'forms.contact.dynamicSubject.returnsStatus' },
    { messageKeySelectOption: 'forms.contact.dynamicSubject.productQuestion' },
    { messageKeySelectOption: 'forms.contact.dynamicSubject.shipping' },
    { messageKeySelectOption: 'forms.contact.dynamicSubject.website' },
    { messageKeySelectOption: 'forms.contact.dynamicSubject.complaint.comment' },
    { messageKeySelectOption: 'forms.contact.dynamicSubject.removeData' },
    { messageKeySelectOption: 'forms.contact.dynamicSubject.praemie' },
    { messageKeySelectOption: 'forms.contact.dynamicSubject.newsletter' },
    // this will not go live until march
    // {
    //   messageKeySelectOption: 'forms.contact.dynamicSubject.reportBarrier',
    //   messageKeyAnswer: 'forms.contact.dynamicAnswer.reportBarrier',
    // },
    { messageKeySelectOption: 'forms.contact.dynamicSubject.otherQuestion' },
  ],
};
